import { useState } from "react";
import Alert from "react-bootstrap/Alert";

const Flash = ({ type, message }) => {
    const [show, setShow] = useState(true);
    return (
        <Alert variant={type} onClose={() => setShow(false)} dismissible>
            <p>{message}</p>
        </Alert>
    );
};

export default Flash;
